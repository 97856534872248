import React, { createContext, ReactElement, ReactNode, useEffect, useReducer, useRef } from 'react';
import Reducer from './reducer';
import { ContextType, GlobalStateInterface } from './types';

export function GlobalStorage({ children }: { children: ReactNode }): ReactElement {
  const [globalState, dispatch] = useReducer(Reducer, initializeState());
  const initialRenderGlobalState = useRef(true);

  useEffect(() => {
    if (initialRenderGlobalState.current) {
      initialRenderGlobalState.current = false;
      return;
    }

    console.log('globalState', globalState);

    sessionStorage.setItem('globalState', JSON.stringify(globalState));
  }, [globalState]);

  return <globalContext.Provider value={{ globalState, dispatch }}>{children}</globalContext.Provider>;
}

export const globalContext = createContext({
  dispatch: {}
} as ContextType);

export const initialState: GlobalStateInterface = {
  currentStep: 1,
  prename: '',
  name: '',
  birthdate:'',
  email: '',
  emailSended: '',
  phone: '',
  street: '',
  housenumber: '',
  zipcode: '',
  city: '',
  bankaccountholder: '',
  iban: '',
  wallettype: '',
  country: '',
  sumInsured: 0,
  xpub: '',
  xpubSended: '',
  applyBitboxOnlySegwit: false,
  applySubAccount: false,
  applyAnalyzing: false,
  signature: '',
  signatureSended: '',
  signatureText: '',
  applyLegalBitcoins: false,
  applyPermanentlyInhabited: false,
  applyPament: false,
  applyDSGVO: false,
  applyInitDocuments: false,
  applyDocuments: false,
  applyProof: false,
  applyEmailNotSecure: false,
  differentRiskLocation: false,
  pincode: '',
  insuredObjectStreet: '',
  insuredObjectHousenumber: '',
  insuredObjectZipcode: '',
  insuredObjectCity: '',
  applyNoConsulting: false,
  applyLimit: false,
  prenameError: false,
  nameError: false,
  birthdateError :false,
  emailError: false,
  emailAPIError: false,
  phoneError: false,
  streetError: false,
  housenumberError: false,
  zipcodeError: false,
  cityError: false,
  bankaccountholderError: false,
  ibanError: false,
  wallettypeError: false,
  countryError: false,
  sumInsuredError: false,
  xpubError: false,
  xpubAPIError: false,
  applyBitboxOnlySegwitError: false,
  applySubAccountError: false,
  applyAnalyzingError: false,
  signatureError: false,
  signatureAPIError: false,
  signatureTextError: false,
  applyLegalBitcoinsError: false,
  applyPermanentlyInhabitedError: false,
  applyPamentError: false,
  applyDSGVOError: false,
  applyInitDocumentsError: false,
  applyDocumentsError: false,
  applyProofError: false,
  applyEmailNotSecureError: false,
  differentRiskLocationError: false,
  pincodeError: false,
  pincodeAPIError: false,
  insuredObjectStreetError: false,
  insuredObjectHousenumberError: false,
  insuredObjectZipcodeError: false,
  insuredObjectCityError: false,
  applyNoConsultingError: false,
  applyLimitError: false,
  quoteId: '',
  policyId: '',
  subpartnerId: '',
  integrationpartnerId: '',
  layoutCleanBackgound: false,
  layoutCustomCSS: ``,
  mockup: false,
  couponCode: '',
  couponCodeDiscount: 0,
  ledgeracountid: ''
};

function initializeState() {
  if (typeof (Storage) !== 'undefined') {
  } else {
    throw new Error('You need to enable Storage to run this app.');
  }

  const fromSessionStorage = JSON.parse(sessionStorage.getItem('globalState') as string);
  return fromSessionStorage || initialState;
}
