import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { GlobalStorage } from './common/GlobalStorage/GlobalStorage';
import SwitchRouter from './common/SwitchRouter/SwitchRouter';
import TransportProviderLedger from './provider/TransportProviderLedger';
import './common/I18n/I18n';
import './fonts/RoubenBold/font.woff';
import './fonts/RoubenSemiBold/font.woff';
import './fonts/RoubenRegular/font.woff';
import './fonts/RoubenMedium/font.woff';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading ...</div>}>
      <TransportProviderLedger>
        <GlobalStorage>
          <SwitchRouter /> 
        </GlobalStorage>
      </TransportProviderLedger>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
