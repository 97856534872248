import React from 'react';

const config: { [key: string]: any } = require('../../config.json');

export const reportAnonUserReachedStep = (userstep: number) => {
    /*const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + config.API_KEY
        },
        body: JSON.stringify({ step: userstep })
    };
    fetch(config.SERVER_URL + 'v', requestOptions)
        .then(response => {
            console.log('response', response);
        });*/

    return;
};